import { Country } from '~/modules/address/types'
import { AddressHttpApiService } from '~/modules/address/api/AddressHttpApiService'
import { AddressHttpApi } from '~/modules/address/interfaces/addressHttpApi'
import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'
import { Region } from '~/modules/address/types/Region'
import SentryLogger from '~/services/sentry/SentryLogger'
import { TInternationalAddressSuggestQueryRequest } from '~/modules/address/types/InternationalAddressSuggestQueryRequest'
import { Address } from '~/common/types/address/Address'

export class AddressService {
  http: AddressHttpApi
  httpInstance: QuickDealHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    this.httpInstance = nuxtApp.$qdHttpApiInstance as QuickDealHttpApiService
    this.http = new AddressHttpApiService(this.httpInstance)
  }

  public getAvailableCountries(): Promise<Array<Country>> {
    return new Promise((resolve, reject) => {
      this.http.getAvailableCountries()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(
            error,
            {
              message: 'Не удалось получить доступные страны',
            },
          )
          reject(error)
        })
    })
  }

  public getAvailableRegions(countryId: Country['id']): Promise<Array<Region>> {
    return new Promise((resolve, reject) => {
      this.http.getAvailableRegions(countryId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(
            error,
            {
              message: 'Не удалось получить доступные регионы',
              countryId,
            },
          )
          reject(error)
        })
    })
  }

  public internationalSuggest(suggest: TInternationalAddressSuggestQueryRequest): Promise<Array<Address>> {
    return new Promise((resolve, reject) => {
      this.http.internationalSuggest(suggest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(
            error,
            {
              message: 'Не удалось получить доступные города',
              suggest,
            },
          )
          reject(error)
        })
    })
  }
}

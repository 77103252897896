import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { AddressHttpApi } from '~/modules/address/interfaces/addressHttpApi'
import { Service } from '~/services/http/Service'
import { Country } from '~/modules/address/types'
import {
  TInternationalAddressSuggestQueryRequest,
} from '~/modules/address/types/InternationalAddressSuggestQueryRequest'
import { Region } from '~/modules/address/types/Region'
import { Address } from '~/common/types/address/Address'

export class AddressHttpApiService extends Service implements AddressHttpApi {
  private endPoints = {
    firstPart: '/v4/address',
    getAvailableCountries: '/available-countries',
    internationalSuggestions: '/international-suggest',
    getAvailableRegions: '/estate-market/addresses/localities',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public getAvailableCountries(): Promise<HttpHandledResponse<Array<Country>>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.getAvailableCountries}`)
  }

  public getAvailableRegions(countryId: Country['id']): Promise<HttpHandledResponse<Array<Region>>> {
    return this.httpInstance.post(`/v4${this.endPoints.getAvailableRegions}`, { id: countryId })
  }

  public internationalSuggest(suggest: TInternationalAddressSuggestQueryRequest): Promise<HttpHandledResponse<Array<Address>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.internationalSuggestions}`, suggest)
  }
}
